import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, Select, Autocomplete, TextField } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../../customAxios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

function AddReferenceOrLinkModal(props) {
  const { id } = useParams();
  const {
    openReferenceModal,
    setOpenReferenceModal,
    setProgress,
    isReference,
    allReference,
    references,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [workItems, setWorkItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const handleClose = () => {
    setOpenReferenceModal(false);
    setSearchItems(null);
    setWorkItems([]);
    setIsLoading(false);
  };

  const handleAddItems = async () => {
    setOpenReferenceModal(false);
    allReference(workItems);
  };
  useEffect(() => {
    if (references) {
      setWorkItems(references);
    }
  }, [references]);
  const handleDeselectTestCase = (Id) => {
    const updatedArray = workItems?.filter((item) => item.id !== Id);
    setWorkItems(updatedArray);
  };

  const fetchItemsFromApi = async (searchQuery) => {
    setIsSearch(true);
    try {
      const response = await api.get(
        `/project/${id}/task-search?keyword=${searchQuery}`
      );
      const filteredSearchItems = response?.data?.filter(
        (item) => !workItems.some((workItem) => workItem.id === item.id)
      );
      setSearchItems(filteredSearchItems);
    } catch (error) {
      toast.error("Oops! something went wrong.");
    } finally {
      setIsSearch(false);
    }
  };

  const itemTypes = [
    { id: 23, name: "User Story" },
    { id: 24, name: "Task" },
    { id: 25, name: "Bug" },
  ];

  const handleSelectWorkItem = (event, value) => {
    setSelectedItem(value);
  };

  const handleAddSelectedItem = () => {
    if (
      selectedItem &&
      !workItems.some((item) => item.id === selectedItem.id)
    ) {
      setWorkItems([...workItems, selectedItem]);
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    if (searchTerm.length >= 1) {
      fetchItemsFromApi(searchTerm);
    }
  }, [searchTerm]);

  return (
    <Modal
      size="lg"
      show={openReferenceModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pt-5 pl-5 add-member_modal"
    >
      <div className="container">
        <div className="main-modal">
          <Modal.Header className="project-modal_header">
            <Modal.Title className="d-flex align-items-center">
              Add {isReference ? "Reference" : "Link"}
            </Modal.Title>
            <FontAwesomeIcon icon={faClose} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body className="project-modal_body">
            <div className="fields">
              <div className="row">
                <div className="col-md-12 border-black">
                  <div className="d-flex align-items-center gap-4">
                    {!isReference && (
                      <div className="field-box w-25 mt-12">
                        <div className="border-black">
                          <h5 className="text-dark">Item Type</h5>
                          <Select
                            className={`mb-2 mt-5px w-100 inputcontrasts inputSelect`}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {itemTypes &&
                              itemTypes.map((type) => (
                                <MenuItem
                                  key={type.id}
                                  className="create-task-type"
                                  value={type.id}
                                >
                                  {type.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </div>
                    )}
                    <div
                      className={`field-box ${isReference ? "w-85" : "w-65"}`}
                    >
                      <div className="border-black">
                        <h5 className="text-dark mt-6px">
                          Select {isReference ? "Work Item" : ""}
                        </h5>
                        <Autocomplete
                          options={searchItems}
                          getOptionLabel={(option) =>
                            `${option.id} - ${option.name}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`Search ${
                                isReference ? "work item" : ""
                              }`}
                              variant="outlined"
                              className="mt-5px w-100"
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          )}
                          onChange={handleSelectWorkItem}
                          value={selectedItem}
                          noOptionsText="There is no task, please search task and select for reference"
                          loading={isSearch}
                          loadingText="Searching..."
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </div>
                    </div>
                    <button
                      className={`btn btn-primary btn-sm ms-auto mt-34`}
                      onClick={handleAddSelectedItem}
                      disabled={!selectedItem}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className="col-md-12 mt-15">
                  {workItems.length > 0 &&
                    workItems.map((item, i) => (
                      <div
                        key={i}
                        className={`${
                          !isReference ? "d-flex align-items-center gap-2" : ""
                        } mb_12`}
                      >
                        {!isReference && (
                          <div className="w-15">
                            <span className="span-gray-color f-14 ms-1">
                              {item.type}
                            </span>
                          </div>
                        )}
                        <div
                          className={`testCase-small-card mb-0 d-flex align-items-center justify-content-between ${
                            !isReference ? "w-85" : "view-test-case"
                          }`}
                        >
                          <div className="d-flex">
                            <div className="icon">
                              <span
                                className={`${
                                  isReference
                                    ? "task-done-blue"
                                    : "text-primary"
                                }`}
                              >
                                {isReference ? null : item?.id}
                              </span>
                            </div>

                            <span className="ms-3 fw-500 text-capitalize">
                              {item.name}
                            </span>
                          </div>
                          <span
                            className="float-end add_cursor span-gray-color"
                            onClick={() => handleDeselectTestCase(item.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="actions">
                <button className="btn btn-cancel btn-sm" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className={`btn btn-primary btn-sm ${
                    isLoading ? "pd-cre-task-load" : ""
                  }`}
                  disabled={isLoading || workItems.length === 0}
                  onClick={handleAddItems}
                >
                  {isLoading ? (
                    <ClipLoader color="#fff" loading={isLoading} size={20} />
                  ) : isReference ? (
                    "Add Reference"
                  ) : (
                    "Add Items"
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
}

export default AddReferenceOrLinkModal;
